import Error from "../components/Error";
import { useRouter } from "next/router";
import ErrorPage from "next/error";
import PostBody from "../components/post-body";
import MoreStories from "../components/more-stories";
import Header from "../components/Header";
import PostHeader from "../components/post-header";
import SectionSeparator from "../components/section-separator";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import {
  getAllPostsWithSlug,
  getPostAndMorePosts,
  getPageBySlug,
  getAllPostsForHome,
  getCustomOptions,
} from "../lib/api";
import PostTitle from "../components/post-title";
import Head from "next/head";
import { CMS_NAME } from "../lib/constants";
import Tags from "../components/tags";
import Blocks from "../components/Blocks";

const Custom404 = ({ footerData, preview }) => (
  <Layout preview={preview}>
    <div>
      <Error />
    </div>
    {footerData && <Footer data={footerData} />}
  </Layout>
);

export async function getStaticProps({ params, preview = false, previewData }) {
  const { footer } = await getCustomOptions(preview);
  return {
    props: {
      preview: preview,
      footerData: footer,
    },
  };
}

export default Custom404;
