import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Header from "../Header";
import Arrow from "../Icons";
import Image from "next/image";
import Link from "next/link";
import background from "./background.jpg";
import MyImage from "../MyImage";

const Error = () => {
  return (
    <div className={styles.errorPage}>
      <Header />
      <MyImage
        layout="fill"
        src={background}
        objectFit="cover"
        alt="background"
      />
      <Container className={styles.container}>
        <h1 className={styles.heading}>404</h1>
        <p className={styles.error}>Seems like nothing is here</p>
        <Button type="white" href="/">
          Back to homepage
        </Button>
      </Container>
    </div>
  );
};

export default Error;
